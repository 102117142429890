const defaultDate = {
    year: 0,
    month: 0,
    monthName: '',
    day: 1,
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
};

const monthLookupTable = [{
    index: 0,
    longName: "Alfnee",
    shortName: "Alf",
    maxDays: 1,
    startDayInYear: 0,
    startDayInLeapYear: 0
}, {
    index: 1,
    longName: "Vetnar",
    shortName: "Vet",
    maxDays: 28,
    startDayInYear: 1,
    startDayInLeapYear: 1
}, {
    index: 2,
    longName: "Gansee",
    shortName: "Gan",
    maxDays: 28,
    startDayInYear: 29,
    startDayInLeapYear: 29
}, {
    index: 3,
    longName: "Daltar",
    shortName: "Dal",
    maxDays: 28,
    startDayInYear: 57,
    startDayInLeapYear: 57
}, {
    index: 4,
    longName: "Efneero",
    shortName: "Efn",
    maxDays: 28,
    startDayInYear: 85,
    startDayInLeapYear: 85
}, {
    index: 5,
    longName: "Vetavneer",
    shortName: "Vtv",
    maxDays: 28,
    startDayInYear: 113,
    startDayInLeapYear: 113
}, {
    index: 6,
    longName: "Zetavneer",
    shortName: "Ztv",
    maxDays: 28,
    startDayInYear: 141,
    startDayInLeapYear: 141
}, {
    index: 7,
    longName: "Favnar",
    shortName: "Fav",
    maxDays: 28,
    startDayInYear: 169,
    startDayInLeapYear: 169
}, {
    index: 8,
    longName: "Thieneer",
    shortName: "Thn",
    maxDays: 1,
    startDayInYear: 197,
    startDayInLeapYear: 197
}, {
    index: 9,
    longName: "Kat",
    shortName: "Kat",
    maxDays: 28,
    startDayInYear: 197,
    startDayInLeapYear: 198
}, {
    index: 10,
    longName: "Lamnar",
    shortName: "Lam",
    maxDays: 28,
    startDayInYear: 225,
    startDayInLeapYear: 226
}, {
    index: 11,
    longName: "Maznee",
    shortName: "Maz",
    maxDays: 28,
    startDayInYear: 253,
    startDayInLeapYear: 254
}, {
    index: 12,
    longName: "Nemb",
    shortName: "Nem",
    maxDays: 28,
    startDayInYear: 281,
    startDayInLeapYear: 282
}, {
    index: 13,
    longName: "Kszneer",
    shortName: "Ksz",
    maxDays: 28,
    startDayInYear: 309,
    startDayInLeapYear: 310
}, {
    index: 14,
    longName: "Omneerkro",
    shortName: "Omn",
    maxDays: 28,
    startDayInYear: 337,
    startDayInLeapYear: 338
}, {
    index: 15,
    longName: "error",
    shortName: "error",
    maxDays: 0,
    startDayInYear: 365,
    startDayInLeapYear: 366
}];

export const fromString = (dateString) => {
    var pattern = getPatternFromString(dateString);
    if (!pattern) {
        throw new Error("Invalid date/time format");
    }
    var partFormat = pattern.pattern;
    const newDate = {...defaultDate};
    var formatLen = partFormat.length;
    for (let i = 0; i < formatLen; i++) {
        switch (partFormat[i]) {
            case "mm":
                var month = monthLookup(pattern.data[i]).index;
                if (month === -1) {
                    throw new Error("Unknown month found");
                }
                if (monthLookupTable[month].maxDays !== 1 && pattern.noDay) {
                    throw new Error("Multi-day month with no day");
                }
                newDate.month = month;
                break;
            case "dd":
                newDate.day = parseInt(pattern.data[i]);
                break;
            case "yy":
                newDate.year = parseInt(pattern.data[i]);
                break;
            case "HH":
                newDate.hours = parseInt(pattern.data[i]);
                break;
            case "MM":
                newDate.minutes = parseInt(pattern.data[i]);
                break;
            case "SS":
                newDate.seconds = parseInt(pattern.data[i]);
                break;
            default:
                throw new Error("Unknown formatting code");
        }
    }

    if (newDate.day > monthLookupTable[newDate.month].maxDays) {
        throw new Error("Day exceeds number allowed per month");
    }

    return fromDateParts(newDate);
}

export const isValidDateString = (dateString) => {
    try {
        fromString(dateString);
        return true;
    } catch {
        return false;
    }
}

const getPatternFromString = (dateString) => {
    var datePattern;
    var datePartMatched;

    // Match dates in format "month, year" (single day months)
    var regexMatches = dateString.match(/^([a-z]+),\s+-?(\d+)/i)
    if (regexMatches) {
        datePartMatched = regexMatches[0];
        datePattern = {
            pattern: ["mm", "yy"],
            data: regexMatches.slice(1),
            noDay: true
        }
    }

    if (!datePattern) {
        // Match dates in format "day month, year"
        regexMatches = dateString.match(/^([0-2]?\d)\s+([a-z]+),\s+-?(\d+)/i)
        if (regexMatches)
        {
            datePartMatched = regexMatches[0];
            datePattern = {
                pattern: ["dd", "mm", "yy"],
                data: regexMatches.slice(1)
            }
        }
    }

    // Match dates in format "month day, year"
    if (!datePattern) {
        regexMatches = dateString.match(/^([a-z]+)\s+([0-2]?\d),\s+-?(\d+)/i)
        if (regexMatches)
        {
            datePartMatched = regexMatches[0];
            datePattern =  {
                pattern: ["mm", "dd", "yy"],
                data: regexMatches.slice(1)
            }
        }
    }

    // If we haven't found a date part yet, we're done
    if (!datePattern) {
        return undefined;
    }

    // Trim off date portion
    dateString = dateString.substring(datePartMatched.length);

    // No more to parse, so we're done.
    if (dateString.length === 0) {
        return datePattern;
    }

    var timeRegexMatches = dateString.match(/^\s*([0-2]?\d):([0-5]\d)(?::([0-5]\d))?/);
    if (timeRegexMatches === undefined) {
        return undefined;
    }

    datePattern.pattern = datePattern.pattern.concat(["HH", "MM"]);
    datePattern.data = datePattern.data.concat(timeRegexMatches.slice(1,3));

    if (timeRegexMatches[3] !== undefined) {
        datePattern.pattern.push("SS");
        datePattern.data.push(timeRegexMatches[3]);
    }

    return datePattern;
}

const monthLookup = (monthString) => {
    if (typeof(monthString) !== 'string') {
        return -1;
    }

    if (monthString.length < 3)
    {
        return -1;
    }

    const lowerCaseMonth = monthString.toLocaleLowerCase();
    if (monthString.length === 3) {
        return monthLookupTable.find(m => m.shortName.toLocaleLowerCase() === lowerCaseMonth);
    }

    return monthLookupTable.find(m => m.longName.toLocaleLowerCase() === lowerCaseMonth);
}

const totalDaysIn400Years = 146097;
const daysIn400YearCycleLookupTable = [];

const isLeapYear = (year) => {
    return (year % 400 === 0)
        || (year % 100 !== 0 && year % 4 === 0);
}

const getDaysIn400YearCycleLookupTable = () => {
    if (daysIn400YearCycleLookupTable.length === 0) {
        var i;
        var totalDays = 0;
        for (i = 0; i < 400; i++) {
            totalDays += isLeapYear(i) ? 366 : 365;
            daysIn400YearCycleLookupTable[i] = totalDays;
        }
    }
    
    return daysIn400YearCycleLookupTable;
}

const daysToYearsIn400YearCycle = (days) => {
    var lookupTable = getDaysIn400YearCycleLookupTable();
    var i;
    var length = lookupTable.length;
    for (i = 0; i < length; i++) {
        if (days < lookupTable[i]) {
            return i;
        }
    }
    
    throw new Error('Math wrong. Should always find the value unless days is more than 400 year cycle or negative');
}

const leapYearTable = [
    { days: 0,   month: 0,  name: 'Alfnee' },
    { days: 1,   month: 1,  name: 'Vetnar' },
    { days: 29,  month: 2,  name: 'Gansee' },
    { days: 57,  month: 3,  name: 'Daltar' },
    { days: 85,  month: 4,  name: 'Efneero' },
    { days: 113, month: 5,  name: 'Vetavneer' },
    { days: 141, month: 6,  name: 'Zetavneer' },
    { days: 169, month: 7,  name: 'Favnar' },
    { days: 197, month: 8,  name: 'Thieneer' },
    { days: 198, month: 9,  name: 'Kat' },
    { days: 226, month: 10, name: 'Lamnar' },
    { days: 254, month: 11, name: 'Maznee' },
    { days: 282, month: 12, name: 'Nemb' },
    { days: 310, month: 13, name: 'Kszneer' },
    { days: 338, month: 14, name: 'Omneerkro' },
    { days: 366, month: -1, name: 'error'}
];

const standardYearTable = [
    { days: 0,   month: 0,  name: 'Alfnee' },
    { days: 1,   month: 1,  name: 'Vetnar' },
    { days: 29,  month: 2,  name: 'Gansee' },
    { days: 57,  month: 3,  name: 'Daltar' },
    { days: 85,  month: 4,  name: 'Efneero' },
    { days: 113, month: 5,  name: 'Vetavneer' },
    { days: 141, month: 6,  name: 'Zetavneer' },
    { days: 169, month: 7,  name: 'Favnar' },
    { days: 197, month: 8,  name: 'should not be found' },
    { days: 197, month: 9,  name: 'Kat' },
    { days: 225, month: 10, name: 'Lamnar' },
    { days: 253, month: 11, name: 'Maznee' },
    { days: 281, month: 12, name: 'Nemb' },
    { days: 309, month: 13, name: 'Kszneer' },
    { days: 337, month: 14, name: 'Omneerkro' },
    { days: 365, month: -1, name: 'error'}
];

export const verdantYearTable = (year) => {
    return isLeapYear(year) ? leapYearTable : standardYearTable;
}

const daysInto400YearCycleForYear = (year) => {
    if (year === 0) {
        return 0;
    }
    var lookupTable = getDaysIn400YearCycleLookupTable();
    return lookupTable[year - 1];
}

export const toDateParts = (date) => {
    var verdantDate = {
    };
    var combatTime = date % 600;
    if (combatTime % 25 !== 0) {
        verdantDate.attack = Math.floor(combatTime / 25) + 1;
        verdantDate.initiative = combatTime % 25;
        date = Math.floor(date / 600) * 6;
    } else {
        date = Math.floor(date / 100);
    }
    verdantDate.seconds = date % 60;
    date = Math.floor(date / 60);
    verdantDate.minutes = date % 60;
    date = Math.floor(date / 60);
    verdantDate.hours = date % 24;
    date = Math.floor(date / 24);

    // Date is now the number of days (verdant)
    var leapYearCycles = Math.floor(date / totalDaysIn400Years);
    var daysIn400yearCycle = date % totalDaysIn400Years;
    var yearsInto400YearCycle = daysToYearsIn400YearCycle(daysIn400yearCycle);
    verdantDate.year = (leapYearCycles * 400) + yearsInto400YearCycle - 2002000;

    date = date - ((leapYearCycles * totalDaysIn400Years) + daysInto400YearCycleForYear(yearsInto400YearCycle));
    verdantDate.days = date;
    var yearTable = verdantYearTable(verdantDate.year);
    var i, length = yearTable.length;
    for (i = length - 1; i >= 0; i--)
    {
        if (yearTable[i].days <= date)
        {
            verdantDate.month = yearTable[i].month;
            verdantDate.monthName = yearTable[i].name;
            verdantDate.day = date - yearTable[i].days + 1;
            break;
        }
    }
    
    return verdantDate;
};

const validateDatePart = (value, min, max, partName) => {
    if (value < min || value > max) {
        throw new Error("Value out of range. Part : '" + partName + "', Value: " + value);
    }
}

const validateCombatDatePart = (value, min, max, partName) => {
    if (value !== undefined) {
        validateDatePart(value, min, max, partName);
    }
}

const validateDayIsCorrectForMonth = (year, month, day) => {
    var dateTable = verdantYearTable(year);
    var daysInMonth = dateTable[month + 1].days - dateTable[month].days;
    if (day > daysInMonth) {
        throw new Error("Month " + month + " only has " + daysInMonth + "days. Part : 'day', Value: " + day);
    }
}

export const fromDateParts = (dateParts) => {
    validateDatePart(dateParts.year, -2002000, 850000, "year");
    validateDatePart(dateParts.month, 0, 14, "month");
    if (!isLeapYear(dateParts.year) && dateParts.month === 8) {
        throw new Error("Thieneer only allowed in leap years. Part : 'month', Value: " + dateParts.month);
    }
    validateDatePart(dateParts.day, 1, 28, "day");
    validateDayIsCorrectForMonth(dateParts.year, dateParts.month, dateParts.day);
    validateDatePart(dateParts.hours, 0, 23, "hours");
    validateDatePart(dateParts.minutes, 0, 59, "minutes");
    validateDatePart(dateParts.seconds, 0, 59, "seconds");
    if (dateParts.attack !== undefined) {
        if (dateParts.initiative === undefined) {
            throw new Error("If attack is specified, initiative must be as well");
        }
        if (dateParts.seconds % 6 !== 0) {
            throw new Error("Combat time requires that seconds be in segment (6 second) increments");
        }

        validateCombatDatePart(dateParts.attack, 1, 24, "attack");
        validateCombatDatePart(dateParts.initiative, 1, 24, "initiative");
    }

    var workingYear = dateParts.year + 2002000;
    var days = Math.floor(workingYear / 400) * totalDaysIn400Years;
    var yearsIn400YearCycle = workingYear % 400;
    days += yearsIn400YearCycle * 365;
    if (yearsIn400YearCycle > 0) {
        days += Math.floor((yearsIn400YearCycle - 1) / 4);
        days -= Math.floor((yearsIn400YearCycle - 1) / 100);
        days += 1; // Allow for year 0 of cycle being a leap year
    }

    days += verdantYearTable(workingYear)[dateParts.month].days + dateParts.day - 1;
    var result = days * 8640000;
    result += dateParts.hours * 360000;
    result += dateParts.minutes * 6000;
    if (dateParts.attack) {
        var seconds = dateParts.seconds - (dateParts.seconds % 6);
        result += seconds * 100 + (dateParts.attack - 1) * 25 + dateParts.initiative;
    } else {
        result += dateParts.seconds * 100;
    }

    return result;
}

export const toString = (date, format) => {
    var dateAsParts = toDateParts(date);
    var isSingleDayMonth = monthLookupTable[dateAsParts.month].maxDays === 1;

    if (!format) {
        format = 'mmmm' + (isSingleDayMonth ? '' : ' dd') + ', yyyy';
    } else if (format.match(/MDY/)) {
        format = format.replace(/MDY/, 'mmmm' + (isSingleDayMonth ? '' : ' dd') + ', yyyy');
    } else if (format.match(/DMY/)) {
        format = format.replace(/DMY/, (isSingleDayMonth ? '' : 'dd ') + 'mmmm, yyyy');
    }

    var result = format;
    result = result.replace(/(dd)/g, dateAsParts.day);
    result = result.replace(/(mmmm)/g, monthLookupTable[dateAsParts.month].longName);
    result = result.replace(/(mmm)/g, monthLookupTable[dateAsParts.month].shortName);
    result = result.replace(/(yyyy)/g, dateAsParts.year);
    if (result.match(/\?AM/g)) {
        result = result.replace(/\?AM/g,
            dateAsParts.hours < 12 ? "am" : "pm");
        var hour = dateAsParts.hours === 0
            ? 12
            : (dateAsParts.hours <= 12
                ? dateAsParts.hours
                : dateAsParts.hours - 12);
        result = result.replace(/(HH)/g, hour);
        result = result.replace(/(MM)/g, ("0" + dateAsParts.minutes).slice(-2) );
    } else {
        result = result.replace(/(HH)/g, ("0" + dateAsParts.hours).slice(-2));
        result = result.replace(/(MM)/g, ("0" + dateAsParts.minutes).slice(-2));
    }

    return result;
}
