import React from 'react';
import PropTypes from 'prop-types';
import './EventEntry.css';
import { Frequencies } from '../../constants/FrequencyUnits';
import EntryRow from './EventRow';

const EventEntry = ({event, onEdit, onDelete}) => {
    return (
        <EntryRow
            name={event.name}
            frequency={`every ${event.frequency} ${Frequencies[event.frequencyUnit].displayNamePlural}\n[Max ${event.maxConcurrent} per ${Frequencies[event.frequencyUnit].displayName}]`}
            onDelete={onDelete}
            onEdit={onEdit}
        />
    );
}

EventEntry.propTypes = {
    event: PropTypes.shape({
        name: PropTypes.string,
        frequency: PropTypes.number,
        frequencyUnit: PropTypes.string,
        maxConcurrent: PropTypes.number,
    }),
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
};

EventEntry.defaultProps = {
    onEdit: null,
    onDelete: null
}

export default EventEntry;