import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Frequencies } from '../../constants/FrequencyUnits';
import './EventEditRow.css'

const EventEditRow = ({event, onCancel, onSave}) => {
    const [state, setState] = useState(event);
    return (
        <div className="event-edit-row">
            <div className="event-edit-row-editors">
                <div className="event-edit-row-editor-name">
                    <label style={{fontSize: '8px', paddingBottom: '2px', display: 'inline-block'}}>Event name</label>
                    <input type="text" onChange={(e) => {setState({...state, name: e.target.value})}} value={state.name} />
                </div>
                <div className="event-edit-row-editor-frequency">
                    <label style={{fontSize: '8px', paddingBottom: '2px', display: 'inline-block'}}>Frequency</label>
                    <div>
                        <div>
                            <span style={{fontSize:'10px'}}>Every</span>
                            <input
                                className="frequency-edit"
                                type="number"
                                onChange={(e) => {
                                    setState({...state, frequency: parseFloat(e.target.value)})
                                }}
                                value={state.frequency} />
                            <select value={state.frequencyUnit} onChange={(e) => { setState({...state, frequencyUnit: e.target.value})}}>
                                {Object.keys(Frequencies).map(k => (
                                    <option key={k} value={k}>{Frequencies[k].displayNamePlural}</option>
                                ))}
                            </select>
                        </div>
                        <div style={{marginTop: '4px'}}>
                            <span style={{fontSize:'10px'}}>Max </span>
                            <input className="max-concurrent-edit" type="number" onChange={(e) => {setState({...state, maxConcurrent: parseInt(e.target.value)})}} value={state.maxConcurrent} />
                            <span style={{fontSize:'10px'}}> per {Frequencies[state.frequencyUnit].displayName.toLowerCase()}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='event-edit-row-buttons'>
                <div className="icon-button" onClick={() => onSave(state)}>
                    <i className="fas fa-check"></i>
                </div>
                <div className="icon-button" onClick={onCancel}>
                    <i className="fas fa-times"></i>
                </div>
            </div>
        </div>
    );
};

EventEditRow.propTypes = {
    event: PropTypes.shape({
        name: PropTypes.string,
        frequency: PropTypes.number,
        frequencyUnit: PropTypes.string,
        maxConcurrent: PropTypes.number,
    }),
    onSave: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default EventEditRow;