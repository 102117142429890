import './EventGen.css';
import { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { v4 as uuid }from 'uuid';
import EventEntry from './EventEntry';
import EventRow from './EventRow';
import EventEditRow from './EventEditRow';
import {
    Frequencies,
    FREQUENCY_MONTH,
    FREQUENCY_DAY,
    FREQUENCY_HOUR,
    FREQUENCY_MINUTE,
    FREQUENCY_WEEK,
    FREQUENCY_DECADE,
    FREQUENCY_CENTURY,
    FREQUENCY_YEAR,
    shortCodeToFrequencyUnit
} from '../../constants/FrequencyUnits';
import { fromDateParts, fromString, toDateParts, toString as toDateString } from '../../utils/dateFunctions';
import GeneratorPanel from './GeneratorPanel';

const emptyEvent = {
    name: '',
    frequency: 1,
    frequencyUnit: FREQUENCY_DAY,
    maxConcurrent: 1,
    saved: false
};

const defaultGenSpec = {
    date: '23 Gansee, 568',
    frequency: 20,
    frequencyUnit: FREQUENCY_DAY
};

const NO_EVENTS_BEING_EDITED = -2;
const NEW_EVENT_BEING_EDITED = -1;

const trimTimeToFrequency = (time, frequencyUnit) => {
    switch (frequencyUnit) {
        case FREQUENCY_MINUTE:
            return time - (time % 6000);

        case FREQUENCY_HOUR:
            return time - (time % 360000);

        case FREQUENCY_DAY:
            return time - (time % 8640000);

        case FREQUENCY_WEEK: {
            const dateParts = toDateParts(time - (time % 8640000));
            dateParts.day = dateParts.day - (dateParts.day % 7);
            return fromDateParts(dateParts);
        }

        case FREQUENCY_MONTH: {
            const dateParts = toDateParts(time - (time % 8640000));
            dateParts.day = 1;
            return fromDateParts(dateParts);
        }

        case FREQUENCY_YEAR: {
            const dateParts = toDateParts(time - (time % 8640000));
            dateParts.day = 1;
            dateParts.month = 0;
            return fromDateParts(dateParts);
        }

        case FREQUENCY_DECADE: {
            const dateParts = toDateParts(time - (time % 8640000));
            dateParts.day = 1;
            dateParts.month = 0;
            dateParts.year = dateParts.year - (dateParts.year % 10);
            return fromDateParts(dateParts);
        }

        case FREQUENCY_CENTURY: {
            const dateParts = toDateParts(time - (time % 8640000));
            dateParts.day = 1;
            dateParts.month = 0;
            dateParts.year = dateParts.year - (dateParts.year % 100);
            return fromDateParts(dateParts);
        }

        default:
            throw new Error('Invalid range type');
    }
}

const generateTime = (startTime, range, rangeUnits) => {
    let endTime = startTime;
    switch (rangeUnits) {
        case FREQUENCY_MINUTE:
            endTime = startTime + (range * 6000);
            break;

        case FREQUENCY_HOUR:
            endTime = startTime + (range * 360000);
            break;

        case FREQUENCY_DAY:
            endTime = startTime + (range * 8640000);
            break;

        case FREQUENCY_WEEK:
            endTime = startTime + (range * 8640000 * 7);
            break;

        case FREQUENCY_MONTH:
            endTime = startTime + (range * 8640000 * 28);
            break;

        case FREQUENCY_YEAR:
            const yearParts = toDateParts(startTime);
            yearParts.year += range;
            endTime = fromDateParts(yearParts);
            break;

        case FREQUENCY_DECADE:
            const decadeParts = toDateParts(startTime);
            decadeParts.year += range * 10;
            endTime = fromDateParts(decadeParts);
            break;

        case FREQUENCY_CENTURY:
            const centuryParts = toDateParts(startTime);
            centuryParts.year += range + 100;
            endTime = fromDateParts(centuryParts);
            break;

        default:
            throw new Error('Invalid range type');
    }

    let time = ((endTime - startTime) * Math.random()) + startTime;
    time = time - (time % 6000);
    return time;
}

const generateEvents = (eventDefs, startDateTime, frequency, frequencyUnits) => {
    const events = [];
    eventDefs.forEach(ed => {
        const eventStart = fromString(startDateTime);
        let eventCount = 0;

        // Cadences match, just calculate probablity based on maxConcurrance
        if (frequencyUnits === ed.frequencyUnit) {
            const probabilty = Math.pow(ed.frequency * ed.maxConcurrent, -1);
            for (let i = 0; i < frequency; ++i) {
                for (let j = 0; j < ed.maxConcurrent; ++j) {
                    if (Math.random() < probabilty) {
                        ++eventCount;
                    }
                }
            }
        } else if (Frequencies[frequencyUnits].ordinal > Frequencies[ed.frequencyUnit].ordinal) {
            // The requested range is in finer units than the event def (eventDef: Days, range units: Hours)
            const probabilty = Math.pow(ed.frequency * ed.maxConcurrent, -1) * Frequencies[frequencyUnits].multiplyFactor[ed.frequencyUnit];
            // console.info(`event: ${ed.name}, prob: ${probabilty}, freq: ${frequency}`);
            for (let i = 0; i < frequency; ++i) {
                for (let j = 0; j < ed.maxConcurrent; ++j) {
                    if (Math.random() < probabilty) {
                        ++eventCount;
                    }
                }
            }
        } else {
            // The requested range is courser units than the event def (eventDef: Days, range units: Months)
            const probabilty = Math.pow(ed.frequency * ed.maxConcurrent, -1);
            const adjustedFrequency = frequency * Frequencies[frequencyUnits].multiplyFactor[ed.frequencyUnit];
            // console.info(`event: ${ed.name}, prob: ${probabilty}, freq: ${adjustedFrequency}`);
            for (let i = 0; i < adjustedFrequency; ++i) {
                for (let j = 0; j < ed.maxConcurrent; ++j) {
                    if (Math.random() < probabilty) {
                        ++eventCount;
                    }
                }
            }
        }

        const eventCounts = {};
        for (let i = 0; i < eventCount; ++i) {
            let generatedTime;
            let trimmedTime;
            do {
                generatedTime = generateTime(eventStart, frequency, frequencyUnits);
                trimmedTime = trimTimeToFrequency(generatedTime, ed.frequencyUnit);
            } while ((eventCounts[trimmedTime] ?? 0) >= ed.maxConcurrent)

            eventCounts[trimmedTime] = (eventCounts[trimmedTime] ?? 0) + 1;
            events.push({
                event: ed,
                id: uuid(),
                time: generatedTime
            })
        }
    });

    return events;
}

const copyEventsToClipboard = (events, setCopyResult) => {
    const csvHeader = ['vtime,date,event'];
    const eventCsv = events.map(ev => `${ev.time},"${toDateString(ev.time, 'MDY HH:MM?AM')}","${ev.event.name}"`);
    var data = [new window.ClipboardItem({ "text/plain": new Blob([[...csvHeader, ...eventCsv].join("\n")], { type: "text/plain" }) })];
    navigator.clipboard.write(data).then(function() {
        setCopyResult('SUCCESS');
    }, function(e) {
        console.error("Unable to write to clipboard.");
        console.dir(e);
        setCopyResult('ERROR');
    });
}

const useLocalStorageAndUrlState = (key, urlParam, urlParams, initialValue) => {
    const [cachedState, setCachedState] = useState(() => {
        const urlSearchParams = new URLSearchParams(urlParams);
        if (urlSearchParams.get(urlParam)) {
            const eventDefs = decodeEventDefs(urlSearchParams.get(urlParam));
            if (eventDefs.length > 0) {
                return eventDefs;
            }
        }

        return JSON.parse(window.localStorage.getItem(key) ?? 'null') ?? initialValue;
    });

    return [cachedState, (newState) => {
        window.localStorage.setItem(key, JSON.stringify(newState));
        setCachedState(newState);
    }]
}

const useLocalStorageState = (key, initialValue) => {
    const [cachedState, setCachedState] = useState(() => (JSON.parse(window.localStorage.getItem(key) ?? 'null') ?? initialValue));

    return [cachedState, (newState) => {
        window.localStorage.setItem(key, JSON.stringify(newState));
        setCachedState(newState);
    }]
}

const encodeEventDefs = (eventDefs) => 
    encodeURIComponent(btoa(JSON.stringify(eventDefs.map(ed => ({
        n:ed.name,
        f:ed.frequency,
        u:Frequencies[ed.frequencyUnit].shortCode,
        m:ed.maxConcurrent
    })))));

const decodeEventDefs = (encodedString) => {
    try {
        return JSON.parse(atob(encodedString)).map(ed => ({
            name: ed.n,
            frequency: ed.f,
            frequencyUnit: shortCodeToFrequencyUnit(ed.u),
            maxConcurrent: ed.m
        }));
    }
    catch (e) {
        return [];
    }
}


const Home = () => {
    const [eventDefs, setEventDefState] = useLocalStorageAndUrlState('event-get/events', 'e', useLocation().search, []);
    const [editIndex, setEditIndex] = useState(NO_EVENTS_BEING_EDITED);
    const [genSpec, setGenSpec] = useLocalStorageState('event-gen/genspec', defaultGenSpec);
    const [events, setEvents] = useState(null);
    const [copyResult, setCopyResult] = useState(null);

    const history = useHistory();

    useEffect(() => {
        if (copyResult) {
            const timerId = setTimeout(
                () => {
                    setCopyResult(null);
                    clearTimeout(timerId);
                }, 3000);
        }
    }, [copyResult, setCopyResult])

    const setEventDefs = (eventDefs) => {
        setEventDefState(eventDefs);
        history.replace((eventDefs?.length ?? 0) > 0 ? `/events?e=${encodeEventDefs(eventDefs)}` : '/events');
    }

    return (
        <div className="Home">
            <div className='event-list'>
                <EventRow key='header' className='event-header' name="Event" frequency="Frequency" />
                {eventDefs.map((e, i) => i !== editIndex ? (
                    <EventEntry
                        key={e.name}
                        event={e}
                        onEdit={editIndex === NO_EVENTS_BEING_EDITED ? (() => {
                            setEditIndex(i)
                        }) : null}
                        onDelete={editIndex === NO_EVENTS_BEING_EDITED ? (() => {
                            setEventDefs(eventDefs.filter(ev => ev !== e))
                        }) : null}
                    />
                ) : (
                    <EventEditRow
                        key={e.name}
                        event={e}
                        onSave={(e) => {
                            const newEvents = [...eventDefs];
                            newEvents[editIndex] = { ...e };
                            setEventDefs(newEvents);
                            setEditIndex(NO_EVENTS_BEING_EDITED);
                        }}
                        onCancel={() => {
                            setEditIndex(NO_EVENTS_BEING_EDITED)
                        }}
                    />
                ))}
                {editIndex === NEW_EVENT_BEING_EDITED
                    && (
                        <EventEditRow
                            key={'NEW'}
                            event={emptyEvent}
                            onSave={(e) => {
                                const newEvents = [...eventDefs, e];
                                setEventDefs(newEvents);
                                setEditIndex(NO_EVENTS_BEING_EDITED);
                            }}
                            onCancel={() => {
                                setEditIndex(NO_EVENTS_BEING_EDITED)
                            }}
                        />
                    )
                }
            </div>
            <button
                disabled={editIndex !== NO_EVENTS_BEING_EDITED}
                onClick={() => { setEditIndex(NEW_EVENT_BEING_EDITED); }}>
                <i className="fas fa-plus-circle"></i> Add Event
            </button>
            {eventDefs.length > 0 && 
                <GeneratorPanel
                    inEdit={editIndex !== NO_EVENTS_BEING_EDITED}
                    generateSpec={genSpec}
                    onGenerate={(gs) => {
                        setGenSpec(gs);

                        const events = generateEvents(eventDefs, gs.date, gs.frequency, gs.frequencyUnit);
                        events.sort((a, b) => a.time - b.time);
                        setEvents(events);
                    }}
                />
           }
            {events && (
                <div className='results'>
                    <div style={{display: 'flex', width: '1000px', border: '1px solid black', padding: '4px'}}>
                        <div>Results</div>
                        <div style={{flex: 1, textAlign:'right'}}>
                            {copyResult === 'SUCCESS' && (<span className='copy-result success'>COPIED</span>)}
                            {copyResult === 'ERROR' && (<span className='copy-result error'>ERROR</span>)}
                            <button onClick={() => copyEventsToClipboard(events, setCopyResult)}><i className="fas fa-clipboard"></i></button>
                            <button style={{display: 'none'}}><i className="fas fa-file-csv"></i></button>
                        </div>
                    </div>
                    <table style={{width: '1000px', border: '1px solid black', borderTop: 0, padding: '4px'}}>
                        <colgroup>
                            <col width='190px'/>
                            <col width='260px'/>
                            <col/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th style={{textAlign: 'left'}}>vtime</th>
                                <th style={{textAlign: 'left'}}>date</th>
                                <th style={{textAlign: 'left'}}>event</th>
                            </tr>
                        </thead>
                        <tbody>
                            {events.map(ev => (
                                <tr key={ev.id}>
                                    <td>{ev.time}</td>
                                    <td>{toDateString(ev.time, 'MDY HH:MM?AM')}</td>
                                    <td>{ev.event.name}</td>
                                </tr>
                            ))}
                        </tbody>

                    </table>
                </div>
            )}
        </div>
    );
}

export default Home;
