import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Frequencies } from '../../constants/FrequencyUnits';
import { isValidDateString } from '../../utils/dateFunctions';
import './GeneratorPanel.css';

const GeneratorPanel = ({inEdit, generateSpec, onGenerate}) => {
    const [genSpec, setGenSpec] = useState({...generateSpec, isValidDate: isValidDateString(generateSpec.date)});

    return (
        <div className='generator-panel'>
            {inEdit && (<div style={{ fontSize: '8px', color: 'red', marginBottom: '4px' }}>There are unsaved events. Any added/changed events not saved will not be included in generated data</div>)}
            <div style={{marginBottom: '8px'}}>
                <label htmlFor="gen-date-input">Date:</label>
                <input 
                    type="text"
                    id="gen-date-input"
                    onChange={(e) => {
                        setGenSpec({
                            ...genSpec,
                            date: e.target.value,
                            isValidDate: isValidDateString(e.target.value)
                        });
                    }}
                    value={genSpec.date}
                    style={{marginRight: '16px'}}
                />
                <label htmlFor="range-value">Range:</label>
                <input 
                    id="range-value"
                    type="number"
                    onChange={(e) => setGenSpec({ ...genSpec, frequency: parseInt(e.target.value) })}
                    value={genSpec.frequency}
                    style={{width: '50px'}}
                />
                <select value={genSpec.frequencyUnit} onChange={(e) => setGenSpec({ ...genSpec, frequencyUnit: e.target.value })}>
                    {Object.keys(Frequencies).map(k => (
                        <option key={k} value={k}>{Frequencies[k].displayNamePlural}</option>
                    ))}
                </select>
                {!genSpec.isValidDate && (<div style={{ fontSize: '8px', color: 'red', marginTop: '4px' }}>Please enter a valid Verdant date</div>)}
            </div>
            <button 
                disabled={!genSpec.isValidDate}
                onClick={() => { onGenerate(genSpec); }}
            >
                <i className="fas fa-dice-d20"></i> Generate
            </button>
        </div>
    );
};

GeneratorPanel.propTypes = {
    inEdit: PropTypes.bool.isRequired,
    onGenerate: PropTypes.func,
    generateSpec: PropTypes.shape({
        date: PropTypes.string,
        frequency: PropTypes.number,
        frequencyUnit: PropTypes.oneOf(Object.keys(Frequencies)),
    }),
};

export default GeneratorPanel;