import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Home from './routes/Home';
import EventGen from './routes/EventGen';

const  App = () => {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route exact path='/'>
            <Home />
          </Route>
          <Route exact path='/events'>
            <EventGen />
          </Route>
          <Route>
            <div>No match</div>
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;
