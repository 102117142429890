
export const FREQUENCY_CENTURY = 'CENTURY';
export const FREQUENCY_DECADE = 'DECADE';
export const FREQUENCY_YEAR = 'YEAR';
export const FREQUENCY_MONTH = 'MONTH';
export const FREQUENCY_WEEK = 'WEEK';
export const FREQUENCY_DAY = 'DAY';
export const FREQUENCY_HOUR = 'HOUR';
export const FREQUENCY_MINUTE = 'MINUTE';

export const Frequencies = {
  [FREQUENCY_CENTURY]: {
    displayName: 'Century',
    displayNamePlural: 'Centuries',
    multiplyFactor: {
      [FREQUENCY_CENTURY]: 1,
      [FREQUENCY_DECADE]: 10,
      [FREQUENCY_YEAR]: 100,
      [FREQUENCY_MONTH]: 1304.4375,
      [FREQUENCY_WEEK]: 5217.75,
      [FREQUENCY_DAY]: 36524.25, 
      [FREQUENCY_HOUR]: 876582,
      [FREQUENCY_MINUTE]: 52594920
    },
    ordinal: 1,
    shortCode: 'CE',
  },
  [FREQUENCY_DECADE]: {
    displayName: 'Decade',
    displayNamePlural: 'Decades',
    multiplyFactor: {
      [FREQUENCY_CENTURY]: 0.1,
      [FREQUENCY_DECADE]: 1,
      [FREQUENCY_YEAR]: 10,
      [FREQUENCY_MONTH]: 130.44375,
      [FREQUENCY_WEEK]: 521.775,
      [FREQUENCY_DAY]: 3652.425, 
      [FREQUENCY_HOUR]: 87658.2,
      [FREQUENCY_MINUTE]: 5259492
    },
    ordinal: 2,
    shortCode: 'DE',
  },
  [FREQUENCY_YEAR]: {
    displayName: 'Year',
    displayNamePlural: 'Years',
    multiplyFactor: {
      [FREQUENCY_CENTURY]: 0.01,
      [FREQUENCY_DECADE]: 0.1,
      [FREQUENCY_YEAR]: 1,
      [FREQUENCY_MONTH]: 13.44375,
      [FREQUENCY_WEEK]: 52.1775,
      [FREQUENCY_DAY]: 365.2425, 
      [FREQUENCY_HOUR]: 8765.82,
      [FREQUENCY_MINUTE]: 525949.2
    },
    ordinal: 3,
    shortCode: 'YE',
  },
  [FREQUENCY_MONTH]: {
    displayName: 'Month',
    displayNamePlural: 'Months',
    multiplyFactor: {
      [FREQUENCY_CENTURY]: 0.0007438400743840074,
      [FREQUENCY_DECADE]: 0.007438400743840074,
      [FREQUENCY_YEAR]: 0.07438400743840074,
      [FREQUENCY_MONTH]: 1,
      [FREQUENCY_WEEK]: 3.881171548117155,
      [FREQUENCY_DAY]: 27.16820083682008, 
      [FREQUENCY_HOUR]: 652.036820083682,
      [FREQUENCY_MINUTE]: 39122.20920502092
    },
    ordinal: 4,
    shortCode: 'MO',
  },
  [FREQUENCY_WEEK]: {
    displayName: 'Week',
    displayNamePlural: 'Weeks',
    multiplyFactor: {
      [FREQUENCY_CENTURY]: 0.00019165349048919553447367160174405,
      [FREQUENCY_DECADE]: 0.0019165349048919553447367160174405,
      [FREQUENCY_YEAR]: 0.019165349048919553447367160174405,
      [FREQUENCY_MONTH]: 0.25765416127641224665804225959465,
      [FREQUENCY_WEEK]: 1,
      [FREQUENCY_DAY]: 7, 
      [FREQUENCY_HOUR]: 168,
      [FREQUENCY_MINUTE]: 10080
    },
    ordinal: 5,
    shortCode: 'WE',
  },
  [FREQUENCY_DAY]: {
    displayName: 'Day',
    displayNamePlural: 'Days',
    multiplyFactor: {
      [FREQUENCY_CENTURY]: 0.000027379070069885076353381657392007,
      [FREQUENCY_DECADE]: 0.00027379070069885076353381657392007,
      [FREQUENCY_YEAR]: 0.0027379070069885076353381657392007,
      [FREQUENCY_MONTH]: 0.03680773732520174952257746565638,
      [FREQUENCY_WEEK]: 0.14285714285714285714285714285714,
      [FREQUENCY_DAY]: 1, 
      [FREQUENCY_HOUR]: 24,
      [FREQUENCY_MINUTE]: 1440
    },
    ordinal: 6,
    shortCode: 'DA',
  },
  [FREQUENCY_HOUR]: {
    displayName: 'Hour',
    displayNamePlural: 'Hours',
    multiplyFactor: {
      [FREQUENCY_CENTURY]: 0.0000011407945862452115147242357246669,
      [FREQUENCY_DECADE]: 0.000011407945862452115147242357246669,
      [FREQUENCY_YEAR]: 0.00011407945862452115147242357246669,
      [FREQUENCY_MONTH]: 0.00153365572188340623010739440235,
      [FREQUENCY_WEEK]: 0.00595238095238095238095238095238,
      [FREQUENCY_DAY]: 0.04166666666666666666666666666667, 
      [FREQUENCY_HOUR]: 1,
      [FREQUENCY_MINUTE]: 60
    },
    ordinal: 7,
    shortCode: 'HO',
  },
  [FREQUENCY_MINUTE]: {
    displayName: 'Minute',
    displayNamePlural: 'Minutes',
    multiplyFactor: {
      [FREQUENCY_CENTURY]: 0.000000019013243104086858578737262077782,
      [FREQUENCY_DECADE]: 0.00000019013243104086858578737262077782,
      [FREQUENCY_YEAR]: 0.0000019013243104086858578737262077782,
      [FREQUENCY_MONTH]: 0.000025560928698056770501789906705819,
      [FREQUENCY_WEEK]: 0.000099206349206349206349206349206349,
      [FREQUENCY_DAY]: 0.00069444444444444444444444444444444, 
      [FREQUENCY_HOUR]: 0.01666666666666666666666666666667,
      [FREQUENCY_MINUTE]: 1
    },
    ordinal: 8,
    shortCode: 'MI',
  },
}

const shortCodeToFrequencyUnitLookup = Object.keys(Frequencies).reduce((t, f) => ({...t, [Frequencies[f].shortCode]: f}), {});
export const shortCodeToFrequencyUnit = (shortCode) => shortCodeToFrequencyUnitLookup[shortCode];

export const FrequencyOrder = Object.keys(Frequencies).sort((a, b) => Frequencies[a].ordinal - Frequencies[b].ordinal);