import React from 'react';
import PropTypes from 'prop-types';
import './EventRow.css';

const EventRow = ({className, name, frequency, onEdit, onDelete}) => {
    return (
        <div className={`event-row ${className}`}>
            <div className='event-row-data'>
                <div className='event-row-name'>{name}</div>
                <div className='event-row-frequency'>{frequency}</div>
            </div>
            <div className='event-row-buttons'>
                {onEdit && (
                    <div className='icon-button' onClick={onEdit}>
                        <i className="fas fa-pen"></i>
                    </div>
                )}
                {onDelete && (
                    <div className='icon-button' onClick={onDelete}>
                        <i className="fas fa-trash-alt"></i>
                    </div>
                )}
            </div>
        </div>
    );
};

EventRow.propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    frequency: PropTypes.string,
    onEdit: PropTypes.func,
    OnDelete: PropTypes.func
};

EventRow.defaultProps = {
    className: '',
    name: '',
    frequency: '',
    onEdit: null,
    onDelete: null
}

export default EventRow;